import {
  faJs,
  faReact,
  faPython,
  faNode,
  faSass,
} from "@fortawesome/free-brands-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./stack.scss";

const faGrapQl: any = {
  prefix: "fac",
  iconName: "graphql", // Any name you like
  icon: [
    400, // width
    400, // height
    [], // ligatures
    "", // unicode (if relevant)
    "M57.468 302.66l-14.376-8.3 160.15-277.38 14.376 8.3z M39.8 272.2h320.3v16.6H39.8z M206.348 374.026l-160.21-92.5 8.3-14.376 160.21 92.5zM345.522 132.947l-160.21-92.5 8.3-14.376 160.21 92.5z M54.482 132.883l-8.3-14.375 160.21-92.5 8.3 14.376z M342.568 302.663l-160.15-277.38 14.376-8.3 160.15 277.38zM52.5 107.5h16.6v185H52.5zM330.9 107.5h16.6v185h-16.6z M203.522 367l-7.25-12.558 139.34-80.45 7.25 12.557z M369.5 297.9c-9.6 16.7-31 22.4-47.7 12.8-16.7-9.6-22.4-31-12.8-47.7 9.6-16.7 31-22.4 47.7-12.8 16.8 9.7 22.5 31 12.8 47.7M90.9 137c-9.6 16.7-31 22.4-47.7 12.8-16.7-9.6-22.4-31-12.8-47.7 9.6-16.7 31-22.4 47.7-12.8 16.7 9.7 22.4 31 12.8 47.7M30.5 297.9c-9.6-16.7-3.9-38 12.8-47.7 16.7-9.6 38-3.9 47.7 12.8 9.6 16.7 3.9 38-12.8 47.7-16.8 9.6-38.1 3.9-47.7-12.8M309.1 137c-9.6-16.7-3.9-38 12.8-47.7 16.7-9.6 38-3.9 47.7 12.8 9.6 16.7 3.9 38-12.8 47.7-16.7 9.6-38.1 3.9-47.7-12.8M200 395.8c-19.3 0-34.9-15.6-34.9-34.9 0-19.3 15.6-34.9 34.9-34.9 19.3 0 34.9 15.6 34.9 34.9 0 19.2-15.6 34.9-34.9 34.9M200 74c-19.3 0-34.9-15.6-34.9-34.9 0-19.3 15.6-34.9 34.9-34.9 19.3 0 34.9 15.6 34.9 34.9 0 19.3-15.6 34.9-34.9 34.9", // svg path data
  ],
};

const typescriptIcon: any = {
  prefix: "fac",
  iconName: "typescript",
  icon: [
    23,
    23,
    [],
    "",
    "M3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5c0-1.105-0.895-2-2-2H5C3.895,3,3,3.895,3,5z M13.666,12.451h-2.118	V19H9.841v-6.549H7.767V11h5.899V12.451z M13.998,18.626v-1.751c0,0,0.956,0.721,2.104,0.721c1.148,0,1.103-0.75,1.103-0.853	c0-1.089-3.251-1.089-3.251-3.501c0-3.281,4.737-1.986,4.737-1.986l-0.059,1.559c0,0-0.794-0.53-1.692-0.53	c-0.897,0-1.221,0.427-1.221,0.883c0,1.177,3.281,1.059,3.281,3.428C19,20.244,13.998,18.626,13.998,18.626z",
  ],
};

export const Stack = () => {
  const stackItems = [
    {
      item: faReact,
      name: "React",
    },
    {
      item: faJs,
      name: "JavaScript",
    },
    {
      item: faPython,
      name: "Python",
    },
    {
      item: faNode,
      name: "Node.js",
    },
    {
      item: faSass,
      name: "SASS",
    },
    {
      item: faDatabase,
      name: "SQL",
    },
    {
      item: faGrapQl,
      name: "GraphQL",
    },
    {
      item: typescriptIcon,
      name: "TypeScript",
    },
  ];

  return (
    <div className="stackWrapper">
      <div className="container">
        {stackItems.map(({ name, item }, index) => {
          return (
            <div className={`item item${index}`} data-info={name}>
              <FontAwesomeIcon className={`stackItem`} icon={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
